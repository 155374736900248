.gutter-box {
    display: none;
    padding: 8px 0;
    background: #00a0e9;
  }
.category-name { 
  margin-top: 0px; 
  margin-bottom: 16px; 
  color: #ffffff;
  text-transform: uppercase; 
}
.ant-card-head {
  background-color: #e3e3e3  !important;
  min-height: 60px !important;
}
.fixture-link {
  color: #464343;
  text-transform: capitalize;
  transition: transform 0.2s;
}
.fixture-link:hover {
  color: #360808;
  text-transform: capitalize;
  /* transform: scale(1.03); */
}
.flex-display {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}
.streams-table {
  padding: 2rem;
  background-color: #464343;
  margin: 0 auto;
}
.ant-table-cell {
  background: #1c1c1c;
  color: white;
}
.ant-table-cell-row-hover {
  background: rgb(149, 16, 13) !important;
}
/* .ant-table-cell:hover {
  color: #1c1c1c;
} */
/* Your CSS file or in a style block */
@media (max-width: 600px) {
  .ant-col-12 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-6 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-card-body {
    padding: 0px;
  }
  .flex-display {
    font-size: 11px;
  }
  .player {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .streams-table {
    padding: 0px;
    width: 100%;
    margin: 0;
  }
  .ant-row {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
  }
  .ant-row p {
    font-size: 11px;
  }
  .category-name { 
    font-size: 11px; 
    padding-bottom: 0px;
  }
}